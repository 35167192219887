import { useEffect } from 'react';

type ReferrerSource = 'android' | 'ios' | 'electron' | 'web';

const useSourceTracker = () => {

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const rawReferrerSource = params.get('source'); // This is a string or null
    const rawReferrerMapId = params.get('kmz'); // This is a string or null

    if(rawReferrerMapId){
      sessionStorage.setItem('referrerMapId', rawReferrerMapId);
    }
    // Narrow down to valid ReferrerSource values
    const isValidSource = (source: string): source is ReferrerSource => {
      return ['android', 'ios', 'electron', 'web'].includes(source);
    };

    if (rawReferrerSource && isValidSource(rawReferrerSource)) {
      const sourceMap: Record<ReferrerSource, string> = {
        android: `https://atlas.diercke.de/map/${rawReferrerMapId}`, // Android app URL
        ios: `de.diercke.atlas.app://map/${rawReferrerMapId}`, // iOS deep link
        electron: `diercke-atlas://-map/${rawReferrerMapId}`, // Electron deep link
        web: `https://atlas.diercke.de/map/${rawReferrerMapId}`, // Default web URL
      };

      const deepLink = sourceMap[rawReferrerSource];
      sessionStorage.setItem('referrerSource', deepLink);
    }
  }, []);
};

export default useSourceTracker;
