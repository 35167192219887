import {useCallback, useState} from "react";
import {AnimatePresence, motion} from "framer-motion";
import {useTranslation} from "react-i18next";


import useBreakpoints from "../../hooks/useBreakpoints";

import Icon from "./Icon";

const INFO_WIDTH_MD = 340;
const INFO_WIDTH_SMALL = 245;

interface InfoBarProps {
  text?: string
}

type MessageInfoProps = {
  onClose(): void,
  text?: string
};
export const MessageInfo: React.FC<MessageInfoProps> = ({onClose, text}) => {
  const {t} = useTranslation();
  //const decimalFormatter = useNumberFormatter({style: 'decimal'});
  const breakpoints = useBreakpoints();
  const width = breakpoints.md ? INFO_WIDTH_MD : INFO_WIDTH_SMALL;

  return (
    <motion.div
      // transition={{ease: 'linear'}}
      animate={{opacity: 1, x: 0}}
      initial={{opacity: 0, x: -50}}
      exit={{opacity: 0, x: -50}}
      style={{width}}
      className="text-diercke-blue pb-1"
    >
      <div className="flex flex-row">
        <h2 className="font-bold pl-5 pt-5 pb-1 grow shrink">{t('Information')}</h2>
        <Icon
          className="grow-0 shrink-0 cursor-pointer"
          name="Schliesskreuz-01"
          title={t('description.close')}
          onClick={onClose}
        />
      </div>
      <div className="px-5 pt-5 flex flex-col text-sm font-karto">
        {text && <h1>{text}</h1>}
      </div>
    </motion.div>
  );
};

const InfoBar: React.FC<InfoBarProps> = ({text}) => {
  const {t} = useTranslation();
  const [isInfoOpen, setIsInfoOpen] = useState(true);

  const toggleInfo = useCallback(() => setIsInfoOpen((prev) => !prev), []);
  const closeInfo = useCallback(() => setIsInfoOpen(false), []);

  return (
    <div className="overflow-hidden  pointer-events-auto">
      <AnimatePresence>{isInfoOpen && <MessageInfo onClose={closeInfo} text={text}/>}</AnimatePresence>
      <div className="flex flex-row">
        <Icon
          // name={isInfoOpen ? 'Info-Filled' : 'Info'}
          name={'Info'}
          title={t('description.info')}
          className="cursor-pointer"
          onClick={toggleInfo}
        />
      </div>
    </div>
  )
}
export default InfoBar;
