import {useQuery} from "@tanstack/react-query";
import React, {MouseEventHandler, useCallback, useRef} from 'react';
import axios from 'axios';
import {useIonRouter} from '@ionic/react';
import {useTranslation} from 'react-i18next';

import BarSpacer from '../shared/BarSpacer';
import Button from '../shared/Button';

type SearchResultData = {
  searchresult: SearchResult[];
};
type SearchResult = {
  capital: boolean;
  country: string;
  countrycode: string;
  dimension: number;
  latitude: number;
  longitude: number;
  nameGer: string;
  nameEng: string;
  nameLoc: string;
  provcapital: string;
};

type SearchProps = {
  onClose: () => void;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
};

const Search: React.FC<SearchProps> = ({onClose, searchTerm, setSearchTerm}) => {
  const {t} = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const router = useIonRouter();

  const handleReset: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      setSearchTerm('');
      e.preventDefault();
    },
    [setSearchTerm]
  );

  const searchResult = useQuery<SearchResult[]>({
    queryKey: ['search', searchTerm],
    queryFn: () =>
      axios
        .get<SearchResultData>('https://globusserver.de/cgi-bin/findtowns-json?find=' + searchTerm)
        .then((result) =>
          result.data.searchresult ? result.data.searchresult.sort((a, b) => b.dimension - a.dimension) : []
        ),
    enabled: !!searchTerm.length
  });

  const handleClick = useCallback(
    (result: SearchResult) => {
      const params = new URLSearchParams(router.routeInfo.search);
      params.set('label', result.nameGer);
      params.set('long', String(result.longitude));
      params.set('lati', String(result.latitude));
      params.set('height', String(50000.0));
      params.set('capital', String(result.capital));
      params.set(
        'dimension',
        String(
          result.dimension < 2e4
            ? 1
            : result.dimension < 1e5
            ? 2
            : result.dimension < 5e5
            ? 3
            : result.dimension < 1e6
            ? 4
            : result.dimension < 3e6
            ? 5
            : 6
        )
      );
      router.push('/globus?' + params.toString());
      onClose();
    },
    [router, onClose]
  );

  return (
    <div className="border border-diercke-blue flex flex-col bg-white rounded-xl">
      <div className="flex flex-row -m-px bg-diercke-blue-250 border border-diercke-blue rounded-xl z-10">
        <input
          placeholder={t('globus:description.searchTown')}
          autoFocus
          ref={inputRef}
          type="text"
          className="grow px-3 rounded-l-xl"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {searchTerm && <Button icon="Schliesskreuz-01" className="-my-0.5" type="reset" onMouseDown={handleReset} />}
        <BarSpacer />
        <Button icon="Suche_Schliessen" className="-my-0.5 rounded-r-xl" type="button" onClick={onClose} />
      </div>
      <div className="rounded-b-xl overflow-hidden">
        {searchResult.data &&
          searchResult.data.map((result, i) => {
            return (
              <div
                key={i}
                className="cursor-pointer p-2 hover:bg-diercke-blue-250 overflow-hidden"
                onClick={() => handleClick(result)}
              >
                {`${result.nameGer} (${result.country})`}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Search;
