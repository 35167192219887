import {IonApp, IonRouterOutlet} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import {Route} from 'react-router';

import useSourceTracker from "../hooks/useSourceTracker";
import {getBaseName} from '../modules/url';

import Site from './Site';


const QUERY_CLIENT = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FC = () => {
  useSourceTracker(); // Call the hook at the top level
  return (
    <IonApp>
      <QueryClientProvider client={QUERY_CLIENT}>
        <IonReactRouter basename={getBaseName()}>
          <IonRouterOutlet id="main">
            <Route
              exact
              path="/"
              render={() => {
                window.location.href = 'https://diercke.de/diercke-globus';
                return null; // Return null to prevent rendering anything in this component
              }}
            />
            <Site/>
          </IonRouterOutlet>
        </IonReactRouter>
        {process.env.REACT_APP_ENABLE_QUERY_DEVTOOLS === 'true' && <ReactQueryDevtools/>}
      </QueryClientProvider>
    </IonApp>
  )
};

export default App;
