import {useQueryClient} from "@tanstack/react-query";
import {useEffect} from 'react';
import debug from 'debug';

import {useUserData} from '../api/data';
import {getAuthBaseUrl, getLoginUrl} from '../modules/url';

const log = debug('diercke:SilentLogin');

// See oauth public directory
const url = getLoginUrl(getAuthBaseUrl() + '/silent.html', 'none');

const SilentLogin = () => {
  const queryClient = useQueryClient();
  const data = useUserData();

  const doSilentLogin = window.self === window.top && data === null;

  useEffect(() => {
    if (doSilentLogin) {
      log(`Creating iframe for url '${url}' ...`);
      const func = async (event: MessageEvent) => {
        if (event.origin === 'https://atlas.diercke.de' && event.data === 'invalidate-user-data') {
          log(`Received message '${event.data}' from '${event.origin}'. Invalidating user data ...`);
          await Promise.all([
            queryClient.invalidateQueries({queryKey: ['user-data']}),
            queryClient.invalidateQueries({queryKey: ['kmz']}),
          ]);
          window.removeEventListener('message', func, false);
        }
      };
      log('Registering message event listener ...');
      window.addEventListener('message', func, false);
      return () => window.removeEventListener('message', func, false);
    }
  }, [doSilentLogin, queryClient]);

  if (!doSilentLogin) {
    return null;
  }

  return <iframe className="hidden" src={url} title={document.title + ' - Silent Login'}/>;
};

export default SilentLogin;
