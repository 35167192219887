import { useEffect } from 'react';
import * as Cesium from 'cesium';
import {useCesium} from "resium";

const MINIMUM_HEIGHT_ABOVE_TERRAIN = 10; // Minimum height above the terrain in meters

const RestrictZoom: React.FC = () => {
  const {scene} = useCesium();
  useEffect(() => {
    if(scene){
      const handler = new Cesium.ScreenSpaceEventHandler(scene.canvas);
      handler.setInputAction(() => {
        const camera = scene.camera;
        const ellipsoid = scene.globe.ellipsoid;

        // Get the camera's current position in Cartographic (longitude, latitude, height)
        const cameraPositionCartographic = ellipsoid.cartesianToCartographic(camera.position);

        // Get the terrain height at the camera's current location
        const terrainHeight = scene.globe.getHeight(cameraPositionCartographic) || 0;

        // If the camera height is below the minimum allowed, clamp it
        if (cameraPositionCartographic.height < terrainHeight + MINIMUM_HEIGHT_ABOVE_TERRAIN) {
          cameraPositionCartographic.height = terrainHeight + MINIMUM_HEIGHT_ABOVE_TERRAIN;

          // Update the camera position
          const newPosition = ellipsoid.cartographicToCartesian(cameraPositionCartographic);
          camera.position = newPosition;
        }
      }, Cesium.ScreenSpaceEventType.WHEEL);

      return () => {
        handler.destroy(); // Clean up the event handler when the component unmounts
      };
    }
  }, [scene]);

  return null;
};

export default RestrictZoom;
