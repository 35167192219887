import {Capacitor} from '@capacitor/core';
import qs from 'qs';

function normalizeBaseUrl(url: string): URL {
  return new URL(url, window.location.href);
}

export const BASE_URL = normalizeBaseUrl((process.env.PUBLIC_URL ?? '').replace(/\/?$/, '/'));
const dierckeSearchUrl = process.env.REACT_APP_DIERCKE_SEARCH_URL || 'https://diercke.de/s';

export function getAuthBaseUrl(): string {
  return process.env.REACT_APP_OAUTH ?? '';
}

export function getBaseName(): string {
  return BASE_URL.pathname;
}

export function getApiBase(): string {
  return process.env.REACT_APP_API_BASE_URL ?? '';
}

export function getKmzUrl(kmzId: number): string {
  return `${getApiBase()}material/kmz/dgo_${kmzId}.kmz`;
}

export function getLegendeImg(kmzId: number): string {
  return `${getApiBase()}material/kmz/legend/dgo_${kmzId}_leg.jpg`;
}

export function getAuthRedirectUrl(redirectTo?: string) {
  return Capacitor.isNativePlatform() ? nativeAppCloseUri : redirectTo || BASE_URL.href;
}

export function getLoginUrl(redirectUri: string, prompt?: 'none') {
  return (
    `${getAuthBaseUrl()}/init?${qs.stringify({
      iss: 'https://wgr.de',
      login_redirect_uri: redirectUri,
      prompt,
    })}`
  );
}

export function getLogoutUrl(redirectUri: string) {
  return (
    `${getAuthBaseUrl()}/deinit?${qs.stringify({
      logout_redirect_uri: redirectUri,
    })}`
  );
}

// Diercke
export function getDierckeUrl(): string {
  return dierckeSearchUrl;
}


export const nativeAppCloseUri = (Capacitor.getPlatform() === 'electron' ? 'diercke-atlas' : 'de.diercke.atlas.app') + '://close_browser_after_redirect';
