import {isOneOf} from '../../utils/assert';
import impl from '../../utils/impl';
import makeChangeableContext from '../../utils/makeMutableContext';

import {GlobusController} from './InitGlobusController';

/**
 * * sat: Satellite image
 * * phy: physical image
 * * satlabels
 *
 * Alternative Image-Providers for testing:
 *
 * * osm
 * * dierckeRoads
 * * dierckeBorders
 * * grid
 **/

export const GLOBUS_IMAGE_VALUES = [
  'sat',
  'satlabels',
  'phys',
  'osm',
  'dierckeRoads',
  'dierckeBorders',
  'grid',
] as const;

export type GlobusImage = (typeof GLOBUS_IMAGE_VALUES)[number];

export const isGlobusImage = isOneOf(...GLOBUS_IMAGE_VALUES);

export type GlobusDefaultMovementMode = 'zoom' | 'rotate' | 'tilt' | 'look';

export type Layer = {
  isEnabled: boolean;
  labelDe: string;
  labelEn: null | string;
};

type Value = {
  image: GlobusImage;
  overlayMap: null | {
    /** KMZ-Image id */
    id: number;
    isVisible: boolean;
    /** 0..=1 */
    opacity: number;
    layers: {
      /** layer ids in list order */
      order: Array<string>;
      byId: Record<string, Layer>;
    };
  };
  controller: GlobusController | null;
  verticalExaggeration: number;
  terrainEnabled: boolean;
  bordersEnabled: boolean;
  transparent50: boolean;
  /** `null` = default cesium pointer/key mapping */
  defaultMovementMode: null | GlobusDefaultMovementMode;
};
const {Provider, useValue: useGlobus} = makeChangeableContext(
  'globus',
  impl<Value>({
    image: 'sat',
    overlayMap: null,
    verticalExaggeration: 1,
    controller: null,
    terrainEnabled: true,
    bordersEnabled: false,
    transparent50: false,
    defaultMovementMode: 'rotate',
  })
);

export {useGlobus};
export default Provider;
