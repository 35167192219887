import { useEffect, useState } from 'react';
import { useCesium } from 'resium';

import { useGlobus } from '../GlobusProvider';
import KmlMap from "../KmlMap";

export const VerticalExaggeration = () => {
  const { verticalExaggeration } = useGlobus();
  const { scene } = useCesium();
  const [refreshKey, setRefreshKey] = useState(0); // Unique key to remount KmlMap

  const roundedVertical = Math.round(verticalExaggeration * 10) / 10;

  useEffect(() => {
    if (scene) {
      scene.verticalExaggeration = verticalExaggeration;
      setRefreshKey((prev) => prev + 1); // Refresh key
      scene.requestRender();
    }
  }, [verticalExaggeration, scene, roundedVertical]);

  return (
    <div>
      <KmlMap key={refreshKey} /> {/* Ensure KmlMap remounts by changing its key */}
    </div>
  );
};
