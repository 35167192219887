import {CesiumTerrainProvider, EllipsoidTerrainProvider, TerrainProvider} from 'cesium';
import {useEffect, useState} from 'react';
import {Scene} from 'resium';

import {useGlobus} from '../GlobusProvider';

export const TerrainScene = () => {
  const {terrainEnabled} = useGlobus();
  const [provider, setProvider] = useState<CesiumTerrainProvider | EllipsoidTerrainProvider | null>(null);

  useEffect(() => {
    const func = async () => {
      if (terrainEnabled) {
        TerrainProvider.heightmapTerrainQuality = 0.8;
        setProvider(await CesiumTerrainProvider.fromUrl('https://globusserver.de/cesiumterrain/terrain'));
        //TODO: Prove that terrain from globusserver brings bug for texture
        // setProvider(await  createWorldTerrainAsync());
      } else {
        setProvider(new EllipsoidTerrainProvider());
      }
    };
    func().catch(console.error);
  }, [terrainEnabled]);

  if (!provider) {
    return null;
  }

  return <Scene terrainProvider={provider}/>;
};
