import {useRef, useLayoutEffect, useState, useCallback, useEffect, cloneElement, FC, Ref, ReactNode} from 'react';
import {createPortal} from 'react-dom';

import {usePopover} from '../../hooks/usePopover';

export interface PopoverRect {
  top: number;
  left: number;
  width: number;
  height: number;
  right: number;
  bottom: number;
}

export interface PopoverState {
  childRect: PopoverRect;
  popoverRect: PopoverRect;
  parentRect: PopoverRect;
  position?: PopoverPosition;
  align?: PopoverAlign;
  padding: number;
}

export type PopoverPosition = 'left' | 'right' | 'top' | 'bottom';
export type PopoverAlign = 'start' | 'center' | 'end';

export interface PopoverProps {
  isOpen: boolean;
  children?: JSX.Element;
  content: JSX.Element;
  position?: PopoverPosition;
  align?: PopoverAlign;
  padding?: number;
  ref?: Ref<HTMLElement>;
  parentElement?: HTMLElement;
  onClickOutside?: (e: MouseEvent) => void;
}

export interface PositionPopoverProps {
  childRect?: PopoverRect;
  popoverRect?: PopoverRect;
  parentRect?: PopoverRect;
  parentRectAdjusted?: PopoverRect;
}

export type PositionPopover = (props?: PositionPopoverProps) => void;

export const DEFAULT_ALIGN: PopoverAlign = 'center';
export const DEFAULT_POSITION: PopoverPosition = 'right';

export const emptyRect: PopoverRect = {
  top: 0,
  left: 0,
  bottom: 0,
  height: 0,
  right: 0,
  width: 0,
};

interface PopoverPortalProps {
  container: Element;
  element: Element;
  children: ReactNode;
}

const PopoverPortal: FC<PopoverPortalProps> = ({container, element, children}) => {
  useLayoutEffect(() => {
    container.appendChild(element);
    return () => {
      container.removeChild(element);
    };
  }, [container, element]);

  return createPortal(children, element);
};

const Popover: FC<PopoverProps> = ({
  isOpen,
  children = null,
  content,
  position = DEFAULT_POSITION,
  align = DEFAULT_ALIGN,
  padding = 0,
  parentElement = window.document.body,
  onClickOutside,
}) => {
  const prevIsOpen = useRef(false);
  const prevPosition = useRef<PopoverPosition | undefined>();
  const childRef = useRef<HTMLElement | undefined>();

  const [popoverState, setPopoverState] = useState<PopoverState>({
    align,
    position,
    padding,
    childRect: emptyRect,
    popoverRect: emptyRect,
    parentRect: emptyRect,
  });

  const {updatePosition, popoverElement} = usePopover({
    isOpen,
    childRef,
    parentElement,
    position,
    align,
    padding,
    onPositionPopover: setPopoverState,
  });

  useLayoutEffect(() => {
    let shouldUpdate = true;
    const updatePopover = () => {
      prevIsOpen.current = isOpen;
      if (!isOpen || !shouldUpdate) {
        return;
      }

      const childRect = childRef.current?.getBoundingClientRect();
      const popoverRect = popoverElement.getBoundingClientRect();
      const shouldUpdatePosition = (() => {
        if (!childRect) {
          return false;
        }

        if (
          popoverRect.width !== popoverState.popoverRect.width ||
          popoverRect.height !== popoverState.popoverRect.height ||
          popoverState.padding !== padding ||
          popoverState.align !== align ||
          position !== prevPosition.current
        ) {
          return true;
        }

        if (childRect === popoverState.childRect) {
          return false;
        }

        return (
          childRect?.bottom !== popoverState.childRect?.bottom ||
          childRect?.height !== popoverState.childRect?.height ||
          childRect?.left !== popoverState.childRect?.left ||
          childRect?.right !== popoverState.childRect?.right ||
          childRect?.top !== popoverState.childRect?.top ||
          childRect?.width !== popoverState.childRect?.width
        );
      })();

      if (shouldUpdatePosition) {
        updatePosition();
      }

      prevPosition.current = position;
      window.requestAnimationFrame(updatePopover);
    };

    window.requestAnimationFrame(updatePopover);

    return () => {
      shouldUpdate = false;
    };
  }, [
    align,
    isOpen,
    padding,
    popoverElement,
    popoverState.align,
    popoverState.childRect,
    popoverState.childRect.height,
    popoverState.childRect.left,
    popoverState.childRect.top,
    popoverState.childRect.width,
    popoverState.padding,
    popoverState.popoverRect.height,
    popoverState.popoverRect.width,
    updatePosition,
    position,
  ]);

  useEffect(() => {
    const handleOnClickOutside = (e: MouseEvent) => {
      if (isOpen && !popoverElement?.contains(e.target as Node) && !childRef.current?.contains(e.target as Node)) {
        onClickOutside?.(e);
      }
    };

    const handleWindowResize = () => {
      if (childRef.current) {
        window.requestAnimationFrame(() => updatePosition());
      }
    };

    window.addEventListener('click', handleOnClickOutside, true);
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('click', handleOnClickOutside, true);
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [isOpen, onClickOutside, popoverElement, updatePosition]);

  const handleRef = useCallback((node: HTMLElement) => {
    childRef.current = node;
  }, []);

  const childrenWithRef = children && cloneElement(children, {ref: handleRef});

  return (
    <>
      {childrenWithRef}
      {isOpen && (
        <PopoverPortal element={popoverElement} container={parentElement}>
          {content}
        </PopoverPortal>
      )}
    </>
  );
};

export default Popover;
