import {useQuery} from "@tanstack/react-query";
import {isString} from 'lodash';
import qs from 'qs';

import {getApiBase, getDierckeUrl, getKmzUrl} from '../modules/url';
import {createIsArrayWithItems, ensureIs} from '../utils/assert';
import {fetchJson, sendRequest} from '../utils/httpRequestHelper';
import {isRecord} from '../utils/isRecord';
import {isArrayVec4, Vec4} from '../utils/vec4';

import type {ImageDataType, License, User} from './types';

const isArrayContainingStrings = createIsArrayWithItems(isString);

export function useUserData(): User | null | undefined {
  const {data} = useQuery({
    queryKey: ['user-data'],
    networkMode: 'always',
    queryFn: async () => {
      const {data, status, headers} = await sendRequest({
        url: getApiBase() + 'user/',
        headers: {
          accept: 'application/json',
        },
        validateStatus(status) {
          // allow 403 = Forbidden
          return (200 <= status && status <= 299) || status === 403;
        },
      });

      if (status === 403) {
        return null;
      }

      if (headers['content-type']?.startsWith('application/json') === false) {
        throw new Error(`http fetch error: got non-json content-type for user data`);
      }

      return data.data ? (data.data as User) : null;
    }
  });
  return data;
}

export function useLicensesData(): License[] | null {
  const user = useUserData();

  const {data} = useQuery({
    queryKey: ['user-licenses'],
    async queryFn() {
      const {data} = await fetchJson({
        url: getApiBase() + 'database/licenses',
      });
      return data ? (data as License[]) : null;
    },
    enabled: user !== null,
  });
  return data ?? null;
}

export function useImageData(kmzId: number | null) {
  const queryKey = 'image-data';
  return useQuery({
    queryKey: [queryKey, kmzId],
    enabled: kmzId !== null,
    queryFn: async (): Promise<ImageDataType> => {
      const {data} = await fetchJson({
        url: `${getApiBase()}database/${queryKey}?${qs.stringify({params: [kmzId]})}`,
      });
      return data[0];
    },
  });
}

export function useKmzGeoFrame(kmzId: number | null) {
  return useQuery({
    queryKey: ['kmz-geo-frame', kmzId],
    enabled: kmzId !== null,
    queryFn: async () => {
      const result = await fetchJson({
        url: `${getApiBase()}db/geoframe/${kmzId}`,
      });
      const data = ensureIs(result.data, isRecord);
      if (!isArrayContainingStrings(data.box) || !isArrayVec4(data.box)) {
        throw new Error('The geo frame box has an unexpected type');
      }
      return {box: data.box.map(parseFloat) as Vec4};
    },
  });
}

export function useIsKmzAvailable(kmzId: number | null) {
  return useQuery({
    queryKey: ['kmz', kmzId],
    enabled: kmzId !== null,
    queryFn: async () => {
      if (kmzId === null) {
        return false;
      }

      const {status} = await sendRequest({
        url: getKmzUrl(kmzId),
        validateStatus: (status) => (200 <= status && status <= 299) || status === 403,
      });

      return 200 <= status && status <= 299;
    },
  });
}

export function useDierckeImageUrl(mapId: null | number) {
  const queryKey = 'image-isbn-details';
  const {data: dierckeUrl} = useQuery(
    {
      queryKey: ['diercke', queryKey, mapId],
      networkMode: 'always',
      enabled: mapId !== null,
      queryFn: async (): Promise<string | null> => {
        const {data} = await fetchJson({
          url: `${getApiBase()}v2/database/${queryKey}?${qs.stringify({params: [mapId]})}`,
        });
        const item = data[0];
        if (!item) {
          return null;
        }
        const isbnId = [
          item.isbn,
          item.book_page.toString().padStart(3, '0'),
          item.book_img_nr.toString().padStart(2, '0'),
          item.book_var_nr.toString().padStart(2, '0'),
        ].join('_');
        return getDierckeUrl()+`/${isbnId}`;
      },
    }
  );
  return dierckeUrl;
}
