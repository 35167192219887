import React, {Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import Modal from 'react-modal';
import {setupIonicReact} from '@ionic/react';

import App from './components/App';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
//import reportWebVitals from './reportWebVitals';
import './modules/i18n';

setupIonicReact({
  mode: 'ios', // only ios for now as iPad is the main target platform
});

Modal.setAppElement('#root');

/* Theme variables */
// import './theme/base.css';

const rootContainer = document.getElementById('root');
if (!rootContainer) {
  throw Error('Root container not found in document');
}
const root = createRoot(rootContainer);
root.render(
  <React.StrictMode>
    <Suspense fallback="...is loading">
      <App/>
    </Suspense>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
