import * as Cesium from 'cesium';
import 'cesium/Build/Cesium/Widgets/widgets.css';
import {memo} from 'react';
import {Viewer, Camera} from 'resium';

import {getApiBase} from '../../modules/url';

import {CameraController} from './CameraController';
import {VerticalExaggeration} from './overlay/VerticalExaggeration';
import {RemoveCesiumLogo} from './RemoveCesiumLogo';
import {BorderImageryProvider} from './BorderImageryProvider';
import KmlMap from './KmlMap';
import CameraFlyToCoords, {UpdatePositionProps} from './CameraFlyToCoords';
import InitGlobusController from './InitGlobusController';
import RestrictZoom from "./RestricZoom";
import TileImageryProviders from './TileImageryProviders';
import LabelEntity from './LabelEntity';
import {TerrainScene} from "./overlay/TerrainScene";

Cesium.Ion.defaultAccessToken =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIwNjcwNmFmZi04ZDkxLTRmYjAtODhkYy1mMTkyOTZlZD' +
  'hiM2UiLCJpZCI6NTgwODAsImlhdCI6MTY1NzgwNTQ3MX0.QY98b0p0fpA7IG-ztNteMS8c1Rse0iPfSnkaOBCGNzg';

Cesium.TrustedServers.add(new URL(getApiBase()).host, 443);

const Globus: React.FC<UpdatePositionProps> = memo(({position, boundingBox}) => {

  return (
    <Viewer
      className="w-full h-full"
      homeButton={false}
      infoBox={false}
      timeline={false}
      navigationHelpButton={false}
      animation={false}
      baseLayerPicker={false}
      sceneModePicker={false}
      fullscreenButton={false}
      geocoder={false}
      selectionIndicator={false}
      requestRenderMode // Improve performance (https://cesium.com/blog/2018/01/24/cesium-scene-rendering-performance/)
      maximumRenderTimeChange={Infinity} // Never render without requests because we have no animations (e.g. video)
    >
      <RestrictZoom/>
      <TerrainScene />
      <RemoveCesiumLogo />
      <Camera percentageChanged={0.01} />
      <CameraFlyToCoords position={position} boundingBox={boundingBox} />
      <InitGlobusController />
      <CameraController />
      <KmlMap />
      <TileImageryProviders />
      <BorderImageryProvider />
      <LabelEntity />
      <VerticalExaggeration />
    </Viewer>
  );
});

export default Globus;
